import React from "react";
import PropTypes from "prop-types";

class Logo extends React.Component {
    static defaultProps = {
        url: "/",
    };
    render() {
        let svg = (
            <svg width="120px" height="43px" viewBox="0 0 120 43">
                <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="v2_1-default2" transform="translate(-30.000000, -24.000000)">
                        <g
                            id="MC_Logo_Full_Color_Light_Background_RGB"
                            transform="translate(30.000000, 24.000000)"
                        >
                            <polyline
                                id="Fill-2"
                                fill="#100E0D"
                                points="10.4981616 42.8085156 11.6032312 34.6129844 7.94506964 41.1426016 5.37292479 41.1426016 4.1535376 34.5938359 2.32445682 42.8085156 0 42.8085156 2.89604457 30.0556562 5.41103064 30.0556562 7.0686351 38.6916016 11.8699721 30.0556562 14.4611699 30.0556562 12.8607242 42.8085156 10.4981616 42.8085156"
                            />
                            <path
                                d="M21.2610585,31.9513516 L18.4031198,38.098 L21.9469638,38.098 L21.2610585,31.9513516 Z M22.5376045,42.8085156 L22.1946518,40.0894375 L17.4885794,40.0894375 L16.2120334,42.8085156 L13.6589415,42.8085156 L19.8892479,30.0556562 L23.0901393,30.0556562 L24.9954318,42.8085156 L22.5376045,42.8085156 L22.5376045,42.8085156 Z"
                                id="Fill-3"
                                fill="#100E0D"
                            />
                            <polyline
                                id="Fill-4"
                                fill="#100E0D"
                                points="25.6716435 42.8085156 27.9008357 30.0556562 30.2637326 30.0556562 28.0345404 42.8085156 25.6716435 42.8085156"
                            />
                            <polyline
                                id="Fill-5"
                                fill="#100E0D"
                                points="30.0631755 42.8085156 32.2923677 30.0556562 34.6549304 30.0556562 32.7877437 40.7213359 38.0272981 40.7213359 37.6652925 42.8085156 30.0631755 42.8085156"
                            />
                            <path
                                d="M42.302507,43 C40.9942061,43 40.0131476,42.7194922 39.3593315,42.1574687 C38.7045125,41.5961172 38.3779387,40.8489922 38.3779387,39.9171016 C38.3779387,39.6617891 38.4030084,39.3937109 38.4538162,39.1128672 L39.4258496,33.5793047 C39.6668524,32.2385781 40.1809471,31.2841797 40.9691365,30.7164453 C41.7563231,30.1487109 42.9315877,29.8641719 44.4939276,29.8641719 C45.5351532,29.8641719 46.6783287,30.030125 47.923454,30.3620313 L47.4277437,32.3917656 C46.2086908,32.1367891 45.154429,32.0087969 44.2649582,32.0087969 C43.4393315,32.0087969 42.8423398,32.1428359 42.4743175,32.4109141 C42.1056267,32.6789922 41.851922,33.2154844 41.7122006,34.0193828 L40.8738719,38.7876797 C40.8227298,39.0940547 40.7973259,39.3235 40.7973259,39.4766875 C40.7973259,39.9621172 40.9433983,40.3131719 41.2358774,40.5298516 C41.527688,40.7472031 42.0170474,40.855375 42.7026184,40.855375 C43.7187744,40.855375 44.7857382,40.7472031 45.903844,40.5298516 L45.9797214,42.5595859 C44.9254596,42.8531953 43.6997214,43 42.302507,43"
                                id="Fill-6"
                                fill="#100E0D"
                            />
                            <polyline
                                id="Fill-7"
                                fill="#100E0D"
                                points="53.9304735 42.8085156 54.8640669 37.4086562 50.2726462 37.4086562 49.3390529 42.8085156 46.9764903 42.8085156 49.2056825 30.0556562 51.5682451 30.0556562 50.6537047 35.3214766 55.2454596 35.3214766 56.1596657 30.0556562 58.5225627 30.0556562 56.2933705 42.8085156 53.9304735 42.8085156"
                            />
                            <path
                                d="M64.8190529,31.9513516 L61.9614485,38.098 L65.5052925,38.098 L64.8190529,31.9513516 Z M66.0959331,42.8085156 L65.7526462,40.0894375 L61.0465738,40.0894375 L59.7703621,42.8085156 L57.2169359,42.8085156 L63.4475766,30.0556562 L66.648468,30.0556562 L68.5534262,42.8085156 L66.0959331,42.8085156 L66.0959331,42.8085156 Z"
                                id="Fill-8"
                                fill="#100E0D"
                            />
                            <polyline
                                id="Fill-9"
                                fill="#100E0D"
                                points="76.4831198 42.8085156 73.3397214 33.3491875 71.682117 42.8085156 69.3763788 42.8085156 71.605571 30.0556562 74.5587744 30.0556562 77.702507 39.7639141 79.3985515 30.0556562 81.6849025 30.0556562 79.4557103 42.8085156 76.4831198 42.8085156"
                            />
                            <polyline
                                id="Fill-10"
                                fill="#100E0D"
                                points="88.2611699 42.8085156 85.1177716 33.3491875 83.4601671 42.8085156 81.1547632 42.8085156 83.3839554 30.0556562 86.3371588 30.0556562 89.4808914 39.7639141 91.1766017 30.0556562 93.4629526 30.0556562 91.2337604 42.8085156 88.2611699 42.8085156"
                            />
                            <polyline
                                id="Fill-11"
                                fill="#100E0D"
                                points="92.9331476 42.8085156 95.1623398 30.0556562 102.954986 30.0556562 102.573928 32.1428359 97.1628969 32.1428359 96.629415 35.1877734 101.640334 35.1877734 101.278329 37.2749531 96.2674095 37.2749531 95.6577159 40.7213359 101.068747 40.7213359 100.725794 42.8085156 92.9331476 42.8085156"
                            />
                            <polyline
                                id="Fill-12"
                                fill="#100E0D"
                                points="101.916435 42.8085156 104.145627 30.0556562 106.508189 30.0556562 104.641003 40.7213359 109.880557 40.7213359 109.518552 42.8085156 101.916435 42.8085156"
                            />
                            <path
                                d="M113.987967,43 C113.403677,43 112.749526,42.9297891 112.025515,42.7897031 C111.301504,42.6492812 110.691811,42.4574609 110.196435,42.21525 L110.710864,40.3766641 C112.082674,40.7855 113.232201,40.9894141 114.159443,40.9894141 C115.023175,40.9894141 115.598106,40.8812422 115.883565,40.6638906 C116.169694,40.4472109 116.369582,40.0064609 116.4839,39.3426484 C116.547075,38.9852109 116.579164,38.7174687 116.579164,38.5384141 C116.579164,38.2196094 116.451811,37.9864687 116.198106,37.8396641 C115.944067,37.6931953 115.372479,37.5043984 114.483343,37.2749531 C113.416379,37.0065391 112.673315,36.6524609 112.25415,36.2120469 C111.834986,35.7716328 111.625404,35.1941562 111.625404,34.4789453 C111.625404,34.1725703 111.669861,33.7513047 111.758774,33.2154844 C112.000111,31.8623281 112.425627,30.9650391 113.03532,30.5249609 C113.645014,30.0842109 114.775487,29.8641719 116.426741,29.8641719 C117.696936,29.8641719 118.877883,30.030125 119.970585,30.3620313 L119.570474,32.3343203 C118.338384,32.0407109 117.226964,31.8939063 116.236212,31.8939063 C115.410251,31.8939063 114.864401,31.9836016 114.59766,32.1619844 C114.330919,32.3410391 114.146741,32.7428203 114.045125,33.3686719 C113.981616,33.7257734 113.949861,33.9941875 113.949861,34.1725703 C113.949861,34.4789453 114.080223,34.7120859 114.340613,34.8713203 C114.600669,35.0312266 115.14351,35.2193516 115.969471,35.4363672 C117.099944,35.7303125 117.881114,36.0712891 118.312981,36.4609766 C118.744847,36.8503281 118.96078,37.3962266 118.96078,38.098 C118.96078,38.3664141 118.915989,38.7812969 118.827409,39.3426484 C118.662284,40.2745391 118.443008,40.9833672 118.170251,41.468125 C117.896825,41.9535547 117.429861,42.3298047 116.769694,42.5978828 C116.109192,42.8659609 115.181616,43 113.987967,43"
                                id="Fill-13"
                                fill="#100E0D"
                            />
                            <path
                                d="M90.2537047,8.42632031 C93.2727577,9.70355469 96.2908078,10.9804531 99.3098607,12.2573516 C101.657382,13.2523984 104.109192,14.0062422 105.251699,16.5670938 C106.146518,18.5749922 107.041337,20.5822188 107.93649,22.5891094 C107.566797,20.3641953 107.184401,18.2081484 106.819053,15.9617344 C106.393203,13.3511641 104.09883,11.9496328 102.19922,10.4735234 C97.750195,7.02210156 93.3008357,3.57067969 88.8544847,0.119929687 C89.2716435,2.67607812 89.6881337,5.2325625 90.1049582,7.78871094 C90.1413928,8.01177344 90.1938719,8.22207031 90.2537047,8.42632031"
                                id="Fill-14"
                                fill="#359745"
                            />
                            <path
                                d="M86.415376,13.8926953 C89.761337,14.4312031 93.105961,14.9697109 96.4525905,15.5075469 C98.9682451,15.9140313 101.528022,16.0615078 103.242786,18.2766797 C104.63766,20.0796563 106.032535,21.8826328 107.427075,23.6852734 C106.461058,21.5352734 105.501393,19.3899766 104.545404,17.2319141 C103.568691,15.0271563 101.033315,14.1490156 98.7914206,13.2077188 C93.5421727,11.0090078 88.3988858,8.80727344 83.1526462,6.60789062 C84.2209471,8.97960938 85.2367688,11.3006016 86.3050696,13.6723203 C86.3394986,13.7489141 86.3786072,13.8197969 86.415376,13.8926953"
                                id="Fill-15"
                                fill="#359745"
                            />
                            <path
                                d="M107.1239,24.9282422 C107.130251,24.92925 107.135933,24.9299219 107.141281,24.9305938 C105.570585,22.9505781 104.044011,20.957125 102.48234,18.9693828 C100.993872,17.0743594 98.3749304,16.8727969 95.9903064,16.4968828 C90.4111421,15.6224375 84.8072423,14.6814766 79.2304178,13.8056875 C80.8208357,15.8098906 82.3490808,17.8577656 83.9401671,19.8623047 C84.1210028,20.0904063 84.3499721,20.3033906 84.5598886,20.4757266 C86.1406128,21.7724453 88.3253482,21.9266406 90.3987744,22.2501484 C91.2755432,22.3875469 92.1593315,22.5430859 93.0347632,22.6804844 C97.7351532,23.4175313 102.408468,24.1881719 107.109526,24.9262266 C107.116546,24.9248828 107.123231,24.9245469 107.131253,24.9235391 C107.126908,24.9221953 107.124568,24.9208516 107.121226,24.9191719 C107.125237,24.9208516 107.130251,24.9225313 107.134596,24.9245469 C107.131253,24.9258906 107.127577,24.9272344 107.1239,24.9282422"
                                id="Fill-16"
                                fill="#359745"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );

        if (this.props.small) {
            svg = (
                <svg width="50px" height="44px" viewBox="0 0 50 44">
                    <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g
                            id="v2_1-default2"
                            transform="translate(-23.000000, -26.000000)"
                            fill="#359745"
                        >
                            <g id="mc-logo-mark" transform="translate(23.000000, 26.000000)">
                                <path
                                    d="M19.4029392,14.6056219 C24.6082029,16.8194948 29.8117376,19.0327854 35.0170012,21.246076 C39.0644511,22.970824 43.2917107,24.2774865 45.2615503,28.7162958 C46.8043416,32.1966531 48.3471328,35.6758458 49.8905004,39.1544562 C49.2530977,35.2979385 48.593795,31.5607906 47.9638844,27.6670062 C47.2296609,23.1420177 43.273845,20.7126969 39.9986553,18.1541073 C32.3279224,12.1716427 24.6566132,6.18917812 16.9904908,0.207878125 C17.7097301,4.63853542 18.4278167,9.069775 19.1464797,13.5004323 C19.2092979,13.887074 19.2997791,14.2515885 19.4029392,14.6056219"
                                    id="Fill-14"
                                />
                                <path
                                    d="M12.7851311,24.0806719 C18.5540294,25.0140854 24.3206224,25.947499 30.0906733,26.8797479 C34.4280088,27.5843208 38.8414177,27.8399469 41.7979061,31.6795781 C44.2028624,34.8047375 46.6078187,37.9298969 49.0121986,41.054474 C47.3466526,37.3278073 45.6920565,33.6092927 44.0437998,29.868651 C42.3598117,26.0470708 37.9884737,24.5249604 34.123139,22.8933792 C25.0727116,19.0822802 16.2049755,15.2659406 7.1597349,11.4536771 C9.00163289,15.5646562 10.7530497,19.5877094 12.5949477,23.6986885 C12.6543079,23.831451 12.7217366,23.9543146 12.7851311,24.0806719"
                                    id="Fill-15"
                                />
                                <path
                                    d="M48.4894823,43.2089531 C48.5004322,43.2107 48.5102296,43.2118646 48.5194506,43.2130292 C45.8113534,39.7810021 43.1793296,36.3256833 40.4867928,32.8802635 C37.9204687,29.5955562 33.4050523,29.2461812 29.2936317,28.5945969 C19.6743829,27.0788917 10.0124868,25.4478927 0.397272116,23.9298583 C3.13937182,27.4038104 5.77427721,30.9534604 8.51752954,34.4279948 C8.82931515,34.8233708 9.2240899,35.1925437 9.58601479,35.4912594 C12.3114014,37.7389052 16.0781865,38.0061771 19.6530593,38.566924 C21.1647296,38.8050812 22.6885025,39.0746823 24.1978676,39.3128396 C32.3019883,40.5903875 40.3594275,41.9261646 48.4647008,43.2054594 C48.4768034,43.2031302 48.4883297,43.2025479 48.5021612,43.200801 C48.4946691,43.1984719 48.4906349,43.1961427 48.4848718,43.1932312 C48.4917875,43.1961427 48.5004322,43.1990542 48.5079243,43.2025479 C48.5021612,43.2048771 48.4958217,43.2072062 48.4894823,43.2089531"
                                    id="Fill-16"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            );
        }

        return (
            <a href={this.props.url}>
                <span>{svg}</span>
            </a>
        );
    }
}

Logo.propTypes = {
    small: PropTypes.bool, // default to full size logo
    url: PropTypes.string, //logo link
};

export default Logo;
