import React from "react";
import PropTypes from "prop-types";

class TextField extends React.Component {
    render() {
        const label = this.props.label ? (
            <label htmlFor={this.props.id}>{this.props.label}</label>
        ) : (
            ""
        );

        const isDisabledClass = this.props.disabled ? "is-disabled" : "";
        const isSearchClass =
            this.props.type === "search" ? "is-search" + " " + isDisabledClass : "";

        let searchIcon = "";
        if (this.props.type === "search") {
            searchIcon = (
                <div className="search-icon">
                    <svg width="14px" height="14px" viewBox="0 0 14 14">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g
                                id="OutboundLogs-DomainAdmin"
                                transform="translate(-304.000000, -144.000000)"
                                fill="#ABB3B0"
                            >
                                <path
                                    d="M304.385178,157.614822 C304.898749,158.128393 305.731176,158.128393 306.244774,157.614822 L308.565221,155.294347 C309.519218,155.906355 310.646903,156.270985 311.864494,156.270985 C315.252798,156.270985 318,153.524166 318,150.135479 C318,146.746792 315.252798,144 311.864494,144 C308.475752,144 305.728988,146.746737 305.728988,150.135479 C305.728988,151.35266 306.094082,152.480782 306.705653,153.435216 L304.385178,155.755691 C303.871607,156.269262 303.871607,157.101251 304.385178,157.614822 Z M311.827783,146.444447 C309.769117,146.444447 308.09999,148.113201 308.09999,150.172217 C308.09999,152.231232 309.76914,153.90001 311.827783,153.90001 C313.886775,153.90001 315.555553,152.231232 315.555553,150.172217 C315.555553,148.113201 313.886775,146.444447 311.827783,146.444447 Z"
                                    id="Combined-Shape"
                                    transform="translate(311.000000, 151.000000) scale(-1, 1) translate(-311.000000, -151.000000) "
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            ); //this couold be inserted with <img>
        }

        let labelText = "";
        if (this.props.type === "password") {
            labelText = <div className="mc-form-field-label-text">{this.props.labelText}</div>;
        }

        return (
            <div className={`mc-form-field ${isSearchClass} ${this.props.className}`}>
                {(label || labelText) && (
                    <div className="mc-form-field-label">
                        {label}
                        {labelText}
                    </div>
                )}
                {searchIcon}
                <input
                    ref={input => {
                        this.inputRef = input;
                    }}
                    className={`mc-input-text ${this.props.className || ""}`}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    id={this.props.id}
                    name={this.props.name}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onKeyUp={event => {
                        let handlers = {
                            13: this.props.onEnter,
                            27: this.props.onEscape,
                        };
                        let handler = handlers[event.keyCode];
                        if (handler) {
                            handler(event.target.value);
                        }
                    }}
                    tabIndex={this.props.tabIndex}
                    autoFocus={this.props.autoFocus}
                />
            </div>
        );
    }
}

TextField.propTypes = {
    type: PropTypes.oneOf(["text", "number", "email", "password", "search", "url"]), //Add more type options as needed
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.any,
    labelText: PropTypes.element,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
};

export default TextField;
