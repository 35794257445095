import React from "react";
import PropTypes from "prop-types";

class Button extends React.Component {
    render() {
        let colorClass = "";

        if (this.props.color) {
            switch (this.props.color) {
                case "red":
                    colorClass = "background-red";
                    break;
                case "purple":
                    colorClass = "background-purple";
                    break;
                case "blue":
                    colorClass = "background-blue";
                    break;
                case "grey":
                    colorClass = "background-grey-light";
                    break;
                default:
                    colorClass = colorClass;
            }
        }

        const disabled = this.props.disabled;
        const iconClass = this.props.icon ? "has-icon" : "";
        const iconOnlyClass = this.props.iconOnly ? "is-icon" : "";
        const outlineClass = this.props.outline ? "is-outline" : "";

        return (
            <button
                onClick={this.props.onClick}
                id={this.props.id}
                className={`mc-btn ${colorClass} ${iconClass} ${iconOnlyClass} ${outlineClass}`}
                disabled={disabled}
                tabIndex={this.props.tabIndex}
            >
                {this.props.children}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    color: PropTypes.oneOf(["red", "purple", "blue", "grey"]),
    icon: PropTypes.bool,
    iconOnly: PropTypes.bool,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default Button;
