import React from "react";
import PropTypes from "prop-types";

class Loader extends React.Component {
    render() {
        let sizeClass = "";
        if (this.props.size) {
            if (this.props.size === "small") {
                sizeClass = "is-small";
            } else if (this.props.size === "xsmall") {
                sizeClass = "is-xsmall";
            }
        }

        const whiteLoaderClass = this.props.white ? "is-white" : "";

        return (
            <div className={`loader ${sizeClass} ${whiteLoaderClass} ${this.props.className}`}>
                <div className="loader-layer">
                    <div className="circle-clipper left">
                        <div className="circle" />
                    </div>
                    <div className="center">
                        <div className="circle" />
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle" />
                    </div>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    className: PropTypes.string,
    white: PropTypes.bool,
    size: PropTypes.oneOf(["small", "xsmall"]),
};

export default Loader;
