import React from "react";
import TextField from "../forms/TextField";
import { defineMessages, injectIntl, intlShape, FormattedMessage } from "react-intl";
import Button from "../Button";
import $ from "jquery";
import PublicLayout from "../../layouts/PublicLayout";
import ResponseMessages from "../ResponseMessages";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: loginEmail || loginAttemptEmail || "",
            resendSuccess: false,
            resendError: false,
            isLoading: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleEmailResend = this.handleEmailResend.bind(this);
    }

    //We need this change handler because the email text field becomes read only
    //when you specify a value and don't have a change handler
    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    componentDidMount() {
        $("#email").focus();
    }

    handleEmailResend(event) {
        event.preventDefault();
        let self = this;

        $.ajax({
            beforeSend: function(xhr) {
                xhr.setRequestHeader(csrfHeaderName, csrfToken);
                self.setState({
                    isLoading: true,
                });
            },
            url: "/signup/resend?email=" + encodeURIComponent(loginAttemptEmail),
            type: "GET",
            success: function() {
                self.setState({
                    resendSuccess: true,
                    isLoading: false,
                });
            },
            error: function() {
                self.setState({
                    resendError: true,
                    isLoading: false,
                });
            },
            complete: function() {
                self.setState({
                    isLoading: false,
                });
            },
        });
    }

    render() {
        const { formatMessage } = this.props.intl;

        const formFields = defineMessages({
            emailField: {
                id: "login.emailLabel",
                defaultMessage: "Email",
            },
            passwordField: {
                id: "login.passwordLabel",
                defaultMessage: "Password",
            },
            submitBtn: {
                id: "login.submit",
                defaultMessage: "Log In",
            },
            helpLinkText: {
                id: "login.help",
                defaultMessage: "Help",
            },
            forgotPasswordLinkText: {
                id: "login.forgotPasswordLink",
                defaultMessage: "forgot password?",
            },
        });

        let title = (
            <FormattedMessage defaultMessage="Welcome! Please Login." id="login.pageTitle" />
        );

        let messageDiv = null;

        if (loginError) {
            messageDiv = (
                <ResponseMessages type={"error"}>
                    <FormattedMessage
                        defaultMessage="Error logging in. If the problem persists, please contact support@mailchannels.com"
                        id={loginError}
                        values={{
                            signupLinkText: (
                                <a style={{ color: "red" }} href="/signup">
                                    <FormattedMessage
                                        id="login.signupLinkText"
                                        defaultMessage="Create an account"
                                    />
                                </a>
                            ),
                            resendLink: (
                                <a
                                    style={{ color: "red" }}
                                    onClick={this.handleEmailResend}
                                    href="#"
                                >
                                    <FormattedMessage
                                        id="login.resendLinkText"
                                        defaultMessage="click here to resend"
                                    />
                                </a>
                            ),
                        }}
                    />
                </ResponseMessages>
            );
        }

        let fieldView = null;
        let forgotPasswordMessage = (
            <a href="/forgotPassword" tabIndex={4}>
                {formatMessage(formFields.forgotPasswordLinkText)}
            </a>
        );

        if (!this.state.isLoading) {
            fieldView = (
                <form method="post" action={"/login"}>
                    <input type={"hidden"} value={csrfToken} name={csrfParameterName} />
                    <TextField
                        type={"text"}
                        label={formatMessage(formFields.emailField)}
                        placeholder={formatMessage({
                            id: "login.emailPlaceholder",
                        })}
                        id={"email"}
                        name={"email"}
                        onChange={this.handleEmailChange}
                        value={this.state.email}
                        tabIndex={1}
                    />
                    <TextField
                        type={"password"}
                        label={formatMessage(formFields.passwordField)}
                        labelText={forgotPasswordMessage}
                        id={"password"}
                        name={"password"}
                        tabIndex={2}
                    />
                    <div className="button">
                        <Button tabIndex={3}>{formatMessage(formFields.submitBtn)}</Button>
                    </div>
                </form>
            );
        }

        if (this.state.resendSuccess) {
            messageDiv = (
                <ResponseMessages type={"success"}>
                    <FormattedMessage
                        defaultMessage="Verification email sent. Check your inbox and follow the instructions in the email."
                        id="login.resendSuccessMessage"
                    />
                </ResponseMessages>
            );
        }

        if (this.state.resendError) {
            messageDiv = (
                <ResponseMessages type={"error"}>
                    <FormattedMessage
                        defaultMessage="Error sending verification email. Please contact support@mailchannels.com"
                        id="login.resendErrorMessage"
                    />
                </ResponseMessages>
            );
        }

        let footer = (
            <FormattedMessage
                id="login.signupDescriptionText"
                defaultMessage="New to Mailchannels? {signupLinkText}."
                values={{
                    signupLinkText: (
                        <a href="/signup" tabIndex={5}>
                            <FormattedMessage
                                id="login.signupLinkText"
                                defaultMessage="Create an account"
                            />
                        </a>
                    ),
                }}
            />
        );
        return (
            <div className="loginForm">
                <PublicLayout
                    title={title}
                    isLoading={this.state.isLoading}
                    content={fieldView}
                    messages={messageDiv}
                    footer={footer}
                />
            </div>
        );
    }
}

LoginForm.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LoginForm);
