import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";

export default class ResponseMessages extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(["success", "warning", "error"]),
    };

    static defaultProps = {
        type: "error",
    };

    getIcon(type) {
        switch (type) {
            case "success":
                return <FontAwesomeIcon icon={faCheckCircle} />;
            case "warning":
                return <FontAwesomeIcon icon={faExclamationCircle} />;
            case "error":
                return <FontAwesomeIcon icon={faTimesCircle} />;
            default:
                return null;
        }
    }

    render() {
        let type = this.props.type;
        return (
            <div className="ResponseMessage">
                <div className={`ResponseMessage__container ${type}`}>
                    <div className="ResponseMessage__container__icon">{this.getIcon(type)}</div>
                    <div className="ResponseMessage__container__content">{this.props.children}</div>
                </div>
            </div>
        );
    }
}
