import React from "react";
import PropTypes from "prop-types";
import Logo from "../components/images/Logo";
import Loader from "../components/Loader";

import { Paper, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider, withStyles } from "@material-ui/styles";

export const theme = createMuiTheme({
    palette: {
        primary: { main: "#35a047" },
        secondary: { main: "#616161" },
    },
    typography: {
        useNextVariants: true,
    },
});

const styles = {
    paper: {
        minWidth: "300px",
    },
};

const PublicLayout = props => {
    const { classes, isLoading, content, hideLogo, title, instruction, messages, footer } = props;

    let view;
    if (isLoading) {
        view = (
            <div className="publicLayout__panel__content__loader">
                <Loader />
            </div>
        );
    } else {
        view = <div className="publicLayout__panel__content__field">{content}</div>;
    }
    return (
        <ThemeProvider theme={theme}>
            <div className="publicLayout">
                {!hideLogo && (
                    <div className="publicLayout__logo">
                        <Logo url="https://app.mailchannels.com" />
                    </div>
                )}
                <Paper className={`${classes.paper} publicLayout__panel`}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {title}
                    </Typography>
                    <div className="publicLayout__panel__content">
                        {instruction && (
                            <Typography variant="body2" gutterBottom>
                                {instruction}
                            </Typography>
                        )}
                        {view}
                    </div>
                </Paper>
                <div className="publicLayout__footer">{footer}</div>
                <div className="publicLayout__messages">{messages}</div>
            </div>
        </ThemeProvider>
    );
};

PublicLayout.propTypes = {
    hideLogo: PropTypes.bool, //hide logo or not
    title: PropTypes.any, //title of the public layout
    instruction: PropTypes.any, //messages shown inside the form panel
    isLoading: PropTypes.bool, //loader spinner shown or not shown
    content: PropTypes.any, //field consist of combinations of <textField> and <button> component
    messages: PropTypes.any, //messages shown right below the form panel
    footer: PropTypes.any, //footer messages below the form messages
};

export default withStyles(styles)(PublicLayout);
