import { IntlProvider, addLocaleData } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import localeData from "../../../resources/i18n/json/data.json";
import en from "react-intl/locale-data/en";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { flash, FLASH } from "./addons/flash-redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

addLocaleData([...en]);

//TODO: Get language from browser/settings
const language = "en";
const messages = localeData[language];

class PublicWrapper extends React.Component {
    render() {
        let reducers = { [FLASH]: flash };
        if (this.props.reducers) {
            Object.assign(reducers, this.props.reducers);
        }
        const store = createStore(combineReducers(reducers), applyMiddleware(thunk));
        return (
            <Provider store={store}>
                <IntlProvider locale={language} messages={messages}>
                    {this.props.children}
                </IntlProvider>
            </Provider>
        );
    }
}

PublicWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

export default PublicWrapper;
