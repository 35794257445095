export const SHOW_FLASH = "SHOW_FLASH";
export const HIDE_FLASH = "HIDE_FLASH";
export const FLASH = "FLASH";

const initialState = {
    flashVisible: false,
    flashType: "error",
    flashMessageID: "no.id",
    autoHide: false,
    timeout: undefined,
    additionalMessage: null,
    additionalValues: null,
};

export function showFlash(flashType, flashMessageID, autoHide, timeout, additionalMessage) {
    return showFlashWithValues(
        flashType,
        flashMessageID,
        autoHide,
        timeout,
        additionalMessage,
        null
    );
}

export function showFlashWithValues(
    flashType,
    flashMessageID,
    autoHide,
    timeout,
    additionalMessage,
    additionalValues
) {
    return {
        type: SHOW_FLASH,
        flashType,
        flashMessageID,
        autoHide,
        timeout,
        additionalMessage,
        additionalValues,
    };
}

export function hideFlash() {
    return {
        type: HIDE_FLASH,
    };
}

export function flash(state = initialState, action) {
    switch (action.type) {
        case SHOW_FLASH:
            return Object.assign({}, state, {
                flashVisible: true,
                flashType: action.flashType,
                flashMessageID: action.flashMessageID,
                messageID: action.flashMessageID,
                autoHide: action.autoHide || state.autoHide,
                timeout: action.timeout || state.timeout,
                additionalMessage: action.additionalMessage || state.additionalMessage,
                additionalValues: action.additionalValues || state.additionalValues,
            });
        case HIDE_FLASH:
            return Object.assign({}, state, {
                flashVisible: false,
                filterType: action.filterType,
                additionalMessage: null,
                additionalValues: null,
            });
        default:
            return state;
    }
}
