import React from "react";
import { render } from "react-dom";
import PublicWrapper from "./layouts/PublicWrapper";
import LoginForm from "./components/login/loginForm";

require("../less/app.less");

render(
    <PublicWrapper>
        <LoginForm />
    </PublicWrapper>,
    document.getElementById("loginForm")
);
